import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import transactionService from "./transactionService";

// Thunk to fetch transactions
export const fetchTransactions = createAsyncThunk(
  "api/transactions",
  async ({ fromDate, toDate }, thunkAPI) => {
    try {
      return await transactionService.fetchTransactions({ fromDate, toDate });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Reset action
export const resetTransactionState = createAction("transactions/resetState");

const initialState = {
  transactions: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

// Transaction slice
const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || "Failed to fetch transactions";
      })
      .addCase(resetTransactionState, () => initialState);
  },
});

export default transactionSlice.reducer;

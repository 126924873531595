import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrders, updateAOrder } from "../features/auth/authSlice";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Product",
    dataIndex: "product",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];



const Orders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, []);

  const orderState = useSelector((state) => state?.auth?.orders) || {};
  const orders = orderState.orders || [];

  // Print what's in the order state auth
  console.log("============Config  ====================");
  // console.log(orderSconfig2tate);


  

  const data1 = orders.map((order, index) => ({
    key: index + 1,
    name: `${order?.user?.firstname} ${order?.user?.lastname}`,
    product: (
      <Link to={`/admin/order/${order?._id}`}>View Orders</Link>
    ),
    amount: order?.totalPrice,
    date: new Date(order?.createdAt).toLocaleString(),
    action: (
      <select
        name=""
        defaultValue={order?.orderStatus}
        onChange={(e) => updateOrderStatus(order?._id, e.target.value)}
        className="form-control form-select"
      >
        <option value="Ordered" disabled>
          Ordered
        </option>
     
     
        <option value="Processing">Processing</option>
        <option value="Payments Pending">Payments Pending</option>
        <option value="Dispatched">Dispatched</option>
        <option value="Out for Delivery">Out for Delivery</option>
        <option value="Delivered">Delivered</option>
        <option value="Cancelled">Cancelled</option>
      </select>
    ),
  }));

  const updateOrderStatus = (id, status) => {
    dispatch(updateAOrder({ id, status }));
  };

  return (
    <div>
      <h3 className="mb-4 title">Orders</h3>
      <div>{<Table columns={columns} dataSource={data1} />}</div>
    </div>
  );
};

export default Orders;

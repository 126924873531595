import axios from "axios";
import { config } from "../../utils/axiosconfig"; // Assuming you have a similar config setup
import { base_url } from "../../utils/baseUrl"; // Assuming you have a base URL setup

const fetchTransactions = async ({ fromDate, toDate }) => {
  try {
    const payload = {
      from: fromDate,
      to: toDate,
    };
    const response = await axios.post(`${base_url}transactions/fetch`, payload, config);
    // conslog the data returned
    console.log("Flutter wave response",response.data.data);
    return response.data.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

const transactionService = {
  fetchTransactions,
};

export default transactionService;
